<template>
  <component
    :is="noLink ? 'div' : TheNuxtLink"
    class="org-card input-border rounded-md relative overflow-hidden block"
    :to="noLink ? undefined : href"
  >
    <div class="org-card__banner">
      <AsyncImage
        :src="banner ?? getAbsoluteAssetLink('nami/banner/great-wave.png')"
        alt="banner"
        class="object-center object-cover aspect-[7/2] max-h-[7rem] w-full"
        :aspect="7 / 2"
      />
    </div>
    <div class="flex p-2 pb-3 gap-2" :class="{ 'px-4 pb-6 gap-4': large }">
      <OrganizationAvatar
        :org="org"
        class="org-card__avatar"
        :size="large ? 96 : 48"
        :class="large ? 'mt-[-64px]' : 'mt-[-24px]'"
      />
      <BadgeUsername
        class="org-card__name"
        :class="{ 'font-medium text-lg': large }"
        :verified
      >
        {{ displayName }}
      </BadgeUsername>
      <NamiButton
        v-if="!noFollow"
        class="ml-auto self-start w-20 !py-0.5 flex-shrink-0"
        :button-type="isFollowing ? 'secondary' : 'primary'"
        :disabled="initialFollowPending"
        small
        pill
        @buttonClick="toggleFollow"
        >{{ isFollowing ? "Unfollow" : "Follow" }}
      </NamiButton>
    </div>
    <div v-if="tagline && false" class="org-card__tagline">
      <IconQuote class="flex-shrink-0" />
      <div class="line-clamp-2">
        {{ tagline }}
      </div>
    </div>
  </component>
</template>

<script lang="ts" setup>
import type {
  OrganizationEntity,
  OrganizationRelation,
  PopulateRelationship,
} from "~/src/api";
import { IconQuote } from "@tabler/icons-vue";
import { parseOrgForDisplay } from "~/utils/display/org";

const TheNuxtLink = resolveComponent("TheNuxtLink");

interface Props {
  org: OrganizationEntity | PopulateRelationship<OrganizationRelation>;
  large?: boolean;
  noLink?: boolean;
  noFollow?: boolean;
}

const props = defineProps<Props>();

const { banner, href, displayName, tagline, verified } = parseOrgForDisplay(
  computed(() => props.org),
  "small",
);

const { isFollowing, toggleFollow, initialFollowPending } = useFollow(
  "organization",
  computed(() => props.org.id),
);
</script>

<style scoped lang="postcss">
.org-card {
  &__avatar {
    z-index: 1;
    @apply shadow flex-shrink-0;
  }

  &__name {
    z-index: 1;
  }

  &__banner {
    @apply overflow-hidden select-none w-full relative;
  }

  &__tagline {
    @apply p-2 text-sm opacity-80 flex space-x-2;
  }
}
</style>
